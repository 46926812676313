import { Box } from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import { ReactSVG } from "react-svg";

import arrowDownImg from "images/arrow-down.svg";
import arrowUpImg from "images/arrow-up.svg";

const AccordionLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Box borderTop="1px solid black" borderBottom="1px solid black" mt="1rem">
      <Box
        onClick={() => setIsOpen((_isOpen) => !_isOpen)}
        py="1.5rem"
        w="full"
        display="flex"
        justifyContent="space-between"
        cursor="pointer"
      >
        <Box fontWeight="bold">{isOpen ? "SHOW LESS" : "SHOW MORE"}</Box>
        <Box cursor="pointer">
          <ReactSVG src={isOpen ? arrowUpImg.src : arrowDownImg.src} />
        </Box>
      </Box>
      {isOpen && (
        <Box mt="1rem" pb="1rem">
          {children}
        </Box>
      )}
    </Box>
  );
};

export default AccordionLayout;
