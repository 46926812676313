import { Box } from "@chakra-ui/react";
import EditorJSHTML from "editorjs-html";
import React, { useRef } from "react";
import sanitizeHtml from "sanitize-html";

import Accordion from "@components/atoms/Accordion";

interface RichTextEditorContentProps {
  jsonData?: string;
  sanitizationNotRequired?: boolean;
  useAccordion?: boolean;
}

export const RichTextEditorContent: React.FC<RichTextEditorContentProps> = ({
  jsonData,
  sanitizationNotRequired,
  useAccordion,
}) => {
  let html = "<div></div>";
  let accordionHtml = "<div></div>";

  const editorHtml = useRef(EditorJSHTML());
  const data = jsonData ? (JSON.parse(jsonData) as unknown) : [];
  let htmlData = data;

  try {
    if (useAccordion) {
      const firstHeaderIndex = data?.blocks?.findIndex(
        (block) => block.type === "header"
      );

      const secondHeaderIndex = data?.blocks?.findIndex(
        (block, index) => index !== firstHeaderIndex && block.type === "header"
      );
      htmlData = {
        ...data,
        blocks: data?.blocks?.slice(0, secondHeaderIndex),
      };
      const accordionHtmlData = {
        ...data,
        blocks: data?.blocks?.slice(secondHeaderIndex),
      };
      accordionHtml = editorHtml.current
        .parse(accordionHtmlData || [])
        .join("");
    }
    html = editorHtml.current.parse(htmlData || []).join("");
  } catch {
    // eslint-disable-line
  }

  // see: https://github.com/apostrophecms/sanitize-html
  // this in addition of strip <Script>s also handles problematic props eg, onClick

  if (!sanitizationNotRequired) {
    html = sanitizeHtml(html, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    });
    accordionHtml = sanitizeHtml(accordionHtml, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    });
  }

  return (
    <Box
      sx={{
        ".ce-block__content": {
          maxWidth: "100%",
        },
        "*:last-child": {
          marginBottom: 0,
        },
        p: {
          marginBottom: "1rem",
          fontSize: "1rem",
        },
        h1: {
          marginBottom: "1.875rem",
        },
        h2: {
          marginBottom: "1.25rem",
          fontSize: "1.5rem",
          fontWeight: "600",
        },
        h3: {
          marginBottom: "1rem",
          fontSize: "1.3rem",
        },
        ol: {
          marginBottom: "2rem",
          marginLeft: "16px",
        },
        ul: {
          marginBottom: "2rem",
          marginLeft: "16px",
        },
        "ol li": {
          listStyleType: "decimal",
          marginBottom: "0.5rem",
        },
        "ul li": {
          listStyleType: "disc",
          marginBottom: "0.5rem",
        },
        a: {
          color: "#06c",
          transition: "color 0.3s, text-decoration 0.3s",
          "&:hover": {
            textDecoration: "underline", // Remove underline on hover if desired
          },
        },
        "@media screen and (min-width: 30rem)": {
          h2: {
            fontSize: "2rem",
          },
          h3: {
            fontSize: "1.5rem",
          },
        },
      }}
    >
      <Box
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      {useAccordion && (
        <Accordion>
          <Box
            dangerouslySetInnerHTML={{
              __html: accordionHtml,
            }}
          />
        </Accordion>
      )}
    </Box>
  );
};
